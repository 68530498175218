:root {
  /* hexa  */
  --gray: #f6f6f6;
  --darkGray: gray;
  --darkness: #1b1b1b;
  --lightGray: rgb(236, 234, 234);
  --white: #ffffff;
  --delete: #e74c3c;
  --information: #115293;
  --warn: #e1c848;
  --success: #2bd628;
  --brown: brown;

  --primary: #b45340;
  --primary2: #1c2e3b;

  /* gba */
  --black_rgb: 0, 0, 0;
  --white_rgb: 255, 255, 255;
  --delete_rgb: 231, 76, 60;
  --warn_rgb: 225, 200, 72;

  --primary_rgb: 180, 83, 64;
  --primary2_rgb: 28, 46, 59;

  --toastify-z-index: 999992;

  --border-radius: 0.5rem;
  --font-family: "Roboto", sans-serif;

  /* content paper  */
  --wrapper-color: var(--gray);
  --wrapper-box-shadow: 0rem 0rem 1.25rem rgba(0, 0, 0, 0.1);
  --paper-border: 0.0625rem dashed var(--darkGray);
  --paper-color: var(--white);

  --animation-timer: 0.3s;
}

html {
  font-size: 95%;
  animation: fade-in 1.5s ease-in;
}

* {
  font-family: "Roboto", sans-serif;
}

.App {
  text-align: center;
  background-color: var(--wrapper-color) !important;
  min-height: 100vh;
  /* padding-top: 4rem; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.input-custom-style {
  width: min(100%, 300px);
  height: 2rem;
  margin: 0.25rem 0rem;
  outline-color: var(--primary);
  padding: 0.5rem 0.7rem;
  border: 0.0625rem solid var(--lightGray);
  box-shadow: var(--wrapper-box-shadow);
}

.textarea-custom-style {
  margin: 0.25rem 0rem;
  outline-color: var(--primary);
  padding: 0.5rem 0.7rem;
  border: 0.0625rem solid var(--lightGray);
  box-shadow: var(--wrapper-box-shadow);
}

.primary-button-style {
  text-transform: uppercase;
  width: min(100%, 20rem);
  display: flex;
  justify-content: center;
  align-items: center;

  height: 44px;
  background-color: var(--primary);
  border: 0;
  border-radius: var(--border-radius);
  color: var(--white);
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  box-shadow: var(--wrapper-box-shadow);
  transition: var(--animation-timer);
}

.secondary-button-style {
  text-transform: uppercase;
  width: min(100%, 20rem);

  height: 44px;
  background-image: linear-gradient(
    to top,
    rgba(var(--primary2_rgb), 0.1),
    rgba(var(--primary2_rgb), 0)
  );
  border-radius: var(--border-radius);
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  box-shadow: var(--wrapper-box-shadow);
  transition: var(--animation-timer);

  border: 0.0625rem solid var(--primary2);
  color: var(--primary2);
  cursor: pointer;
}

.primary-button-style:hover,
.secondary-button-style:hover {
  transform: translateY(-5%);
  background-origin: top;
}

.pos_field {
  background-color: #e1e1e1;
  z-index: 1;
  padding: 0rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;

  margin-left: -0.25rem;
  transform: translateY(0.25rem);
  border: 1px solid rgba(var(--black_rgb), 0.3);
}

select {
  z-index: 0;
  min-height: 3.25rem;
}
